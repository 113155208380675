<template>
    <!--c系统订单配送单详情-->
    <div class="SystemOrderDeliveryDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    size="small"
                    label-width="85px"
                >
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="订单编码">
                                <el-input v-model="form.orderCode" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="会员编码">
                                <el-input v-model="form.cmemberCode" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="付款时间">
                                <el-date-picker v-model="form.payTime" value-format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="会员昵称">
                                <el-input v-model="form.memberName" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label-width="100px" label="收银交易编码">
                                <el-input v-model="form.cashTradeCode" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label-width="100px" label="是否生成交易">
                                <el-input :value="form.alreadyCreateTradeFlag | alreadyCreateTradeFlag" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="部门名称">
                                <el-input v-model="form.payeeDeptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label-width="100px" label="实际支付金额">
                                <el-input :value="form.payMoney | money" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="接单人">
                                <el-input v-model="form.orderReceiverName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="配送人">
                                <el-input v-model="form.orderDeliveryerName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="商品总金额">
                                <el-input :value="form.totalMoney | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单号">
                                <el-input v-model="form.orderNo" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="付款类型">
                                <el-input v-model="form.payTypeName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运费">
                                <el-input :value="form.freightMoney | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="创建时间">
                                <el-date-picker v-model="form.createTime" value-format="yyyy-MM-dd" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-card style="margin-top: 8px">
                    <el-table
                        id="printMe"
                        border
                        stripe
                        style="width: 100%"
                        :data="tableData"
                        size="mini"
                        :highlight-current-row="true"
                        max-height="500"
                    >
                        <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                        <el-table-column prop="bar" label="条码" width="140" />
                        <el-table-column prop="count" label="数量" width="120" />
                        <el-table-column prop="skuName" label="商品名称" width="120" />

                        <el-table-column label="零售单价" width="120" prop="retailPrice">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.retailPrice | money }}</span></template
                            >
                        </el-table-column>
                        <el-table-column label="划线价" width="120" prop="markingPrice">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.markingPrice | money }}</span></template
                            >
                        </el-table-column>
                        <el-table-column label="最终销售价格" width="120" prop="salePrice">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.salePrice | money }}</span></template
                            >
                        </el-table-column>
                        <el-table-column label="商品金额" width="120" prop="skuPrice">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.skuSaleMoney | money }}</span></template
                            >
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import MoneyUtils from 'js/MoneyUtils';

export default {
    name: 'SystemOrderDeliveryDetail',
    props: ['form'],
    data() {
        return {
            url: {
                querySystemOrderDeliveryDetail: '/fromSystemc/orderDelivery/orderDeliveryDetail',
            },
            meta: {
                types: [],
            },
            codes: [],
            tableData: [],
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: { code: _this.form.code } };
            Util.queryTable(_this, _this.url.querySystemOrderDeliveryDetail, _params, (data) => {
                _this.tableData = data.data;
            });
        },
    },
    filters: {
        alreadyCreateTradeFlag(alreadyCreateTradeFlag) {
            if (typeof alreadyCreateTradeFlag == 'undefined') {
                return '';
            }
            if (alreadyCreateTradeFlag == true) {
                return '已创建';
            }
            if (alreadyCreateTradeFlag == false) {
                return '未创建';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
